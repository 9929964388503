// 高さ取得
// css=>　height: calc(var(--vh, 1vh) * 100);

var winWidth = $(window).width();

if (winWidth < 1024){
  $(document).ready(function () {
    var hSize = $(window).height();
    $('.menu').height(hSize); // アドレスバーを除いたサイズを付与
  });

  $(window).on('scroll resize', function () {
    var hSize = window.innerHeight;
    $('.menu').height(hSize); // アドレスバーを除いたサイズを付与
  });
}



