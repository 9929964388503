// jQuery start
jQuery(function () {

  // Smooth scroll
  jQuery('a[href^="#"]').click(function () {
    var speed = 800;
    var href = jQuery(this).attr("href");
    var target = jQuery(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    jQuery("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  }); //Smooth scroll END


  // loading
  $(document).ready(function () {
    jQuery(".loading").addClass("active");
  });

  // $(window).on('load', function () {
  //   jQuery(".loading").addClass("active");
  // });



  // scroll class add
  jQuery(window).on("load scroll resize", function () {

    var st = jQuery(window).scrollTop();
    var wh = jQuery(window).height();
    var ww = jQuery(window).width();

    // fast(<1) slow(>1)
    jQuery(".fade, .fadeMask, .fadeUp, .fadeIn").each(function (i) {
      var tg = jQuery(this).offset().top - wh * 0.9;
      if (st > tg) {
        jQuery(this).addClass("active");
      }
    });

    jQuery(".fade-up-fast").each(function (i) {
      var tg = jQuery(this).offset().top - wh * 0.8;
      if (st > tg) {
        jQuery(this).addClass("active");
      }
    });

  }); // scroll class add END


  // Click event humberger menu
  jQuery('.navBar').click(function () {
    jQuery('.menu').toggleClass('open');
    jQuery('.navBar__line').toggleClass('active');
    // jQuery('.header__inner').toggleClass('active');
    // jQuery('.header__body').toggleClass('active');
  });

  jQuery('#js-lookbook').click(function () {
    jQuery('.menuNav1').toggleClass('close');
    jQuery('.menuNav2').toggleClass('open');
  });
  jQuery('#js-lookbookBack').click(function () {
    jQuery('.menuNav1').toggleClass('close');
    jQuery('.menuNav2').toggleClass('open');
  });

  // Procuct List Toggle
  jQuery('.product__ttl').click(function () {
    jQuery(this).delay(5000).toggleClass('open');
    jQuery(this).next('.product__answer').slideToggle();
  });
  jQuery('.stockList__shop').click(function () {
    jQuery(this).delay(5000).toggleClass('open');
    jQuery(this).next('.stockList__info').slideToggle();
  });



});
// jQuery End


// // scroll = class add
// window.onscroll = function () {
//   // go to top btn
//   var page_top = document.getElementById('pageTop');
//   if (window.pageYOffset > 800) {
//     page_top.classList.add("active");
//   } else {
//     page_top.classList.remove("active");
//   }
// }

